import React from 'react'

function ErrorPage() {
  return (
    <div className='container' >
       <h1>404</h1>
    </div>
  )
}

export default ErrorPage
