import React from 'react'
import Layout from '../components/Layout'
import Header from '../components/Header'
import Product from '../components/Product'

function HomePage() {
  return (
    <Layout >
      <div  >
        {/* <Header /> */}
        <Product />
      </div>
    </Layout>
  )
}

export default HomePage
